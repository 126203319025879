<template>
    <div class="p-3">
        <div class="text-center">
            <el-radio-group v-model="status" @change="loadSettlements(true)">
                <el-radio-button :label="null">全部</el-radio-button>
                <el-radio-button :label="1">结算中</el-radio-button>
                <el-radio-button :label="2">已结算</el-radio-button>
            </el-radio-group>
        </div>

        <el-card shadow="never" class="mt-3">
            <div class="row no-gutters">
                <div class="col">
                    <el-date-picker v-model="beginTime" placeholder="开始日期" style="width: 160px;"
                        @change="showSettlementDialog(null)" />
                    <el-date-picker v-model="endTime" placeholder="开始日期" class="ml-2" style="width: 160px;"
                        @change="showSettlementDialog(null)" />
                </div>
            </div>
            <el-table :data="settlements.data" class="mt-2">
                <el-table-column prop="statusName" label="状态" sortable width="100" />
                <el-table-column prop="billNumber" label="单据编号" sortable width="200" />
                <el-table-column prop="companyName" label="水发公司" sortable />
                <el-table-column prop="amount" label="结算金额" sortable align="right" width="150">
                    <template #default="scope">
                        <el-button type="text" @click="showOrderItemsDialog(scope.row)">
                            {{scope.row.amount}} 元
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="settlements.totalRecords"
                :current-page="settlementsPagination.currentPage" :page-size="settlementsPagination.pageSize"
                class="mt-3" @current-change="settlementsPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="orderItemsDialogVisible" title="订单项" :close-on-click-modal="false" width="800px">
            <div class="border rounded">
                <div class="border-bottom p-2">合计金额：{{totalAmount}} 元</div>
                <el-table :data="settlement.orderItems">
                    <el-table-column width="90">
                        <template #default="scope">
                            <el-image :src="scope.row.productSnapshot.pictureUrl"
                                :preview-src-list="[scope.row.productSnapshot.pictureUrl]" fit="fill"
                                style="width: 64px; height: 64px;" />
                        </template>
                    </el-table-column>
                    <el-table-column label="商品">
                        <template #default="scope">
                            <div>{{scope.row.productSnapshot.name}}</div>
                            <div class="text-info">
                                {{scope.row.number}}（{{scope.row.productSnapshot.unit}}）共
                                {{scope.row.totalPrice}} 元
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="买家" align="right" width="120">
                        <template #default="scope">
                            <div>{{scope.row.buyerName}}</div>
                            <div class="text-info">{{$moment.sd(scope.row.paymentTime)}}</div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="p-2">共 {{settlement.orderItems.length}} 条</div>
            </div>
            <template #footer>
                <el-button @click="orderItemsDialogVisible=false">关闭</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                status: 1,
                beginTime: new Date(new Date().getFullYear(), 0, 1),
                endTime: new Date(),
                settlements: {},
                settlementsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                settlement: { orderItems: [] },
                orderItemsDialogVisible: false,
            };
        },
        computed: {
            totalAmount() {
                let result = 0;
                for (const orderItem of this.settlement.orderItems) {
                    result += orderItem.totalPrice;
                }
                return result;
            },
        },
        methods: {
            async loadSettlements(resetPage = false) {
                if (resetPage) {
                    this.settlementsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/Settlement/GetSettlementsBySupplier', {
                    params: {
                        supplierId: this.$store.state.user.id,
                        status: this.status,
                        beginTime: this.$moment(this.beginTime).format('YYYY/M/D'),
                        endTime: this.$moment(this.endTime).format('YYYY/M/D'),
                        pageIndex: this.settlementsPagination.currentPage - 1,
                        pageSize: this.settlementsPagination.pageSize,
                    }
                });
                this.settlements = response.data;
            },
            settlementsPaginationCurrentChange(page) {
                this.settlementsPagination.currentPage = page;
                this.loadSettlements();
            },
            showOrderItemsDialog(settlement) {
                this.settlement = JSON.parse(JSON.stringify(settlement));
                this.orderItemsDialogVisible = true;
            },
        },
        created() {
            this.loadSettlements();
        },
    };
</script>